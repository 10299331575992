import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Layout } from "../components/common";
import { processHtml } from "../utils/processHtml";

import HeaderIllu from "../assets/illustrations/pages/nodejs-react-microservices-kubernetes-training.svg";

const Container = styled.div`
  > h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 2rem 0;
    max-width: 100%;
  }
`

class Trainings extends Component {
  render() {
    const { data } = this.props;
    const { html, plaintext } = data.ghostPage;
    const { htmlX, toc, jumbotronData } = processHtml(html, plaintext);

    return (
      <Layout
        title={data.ghostPage.title}
        subtitle={jumbotronData.subtitle}
        meta={{
          title: data.ghostPage.meta_title,
          description: data.ghostPage.meta_description,
          canonical: data.ghostPage.url,
          featureImage: data.ghostPage.feature_image
        }}
        jumbotronVariant="basic"
        illustrations={
          <img style={{ right: 0, bottom: 0 }} alt="nodejs react microservices kubernetes training" src={HeaderIllu} />
        }
        toc={toc}
        selected="Trainings"
      >
        <Container className="container">{htmlX}</Container>
      </Layout>
    );
  }
}

Trainings.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default Trainings;

export const pageQuery = graphql`
  query GhostTrainingsQuery {
    ghostPage(slug: { in: "trainings" }) {
      html
      plaintext
      title
      meta_title
      meta_description
      url
      feature_image
    }
  }
`;
